.body-home {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0);
  justify-content: center;
  padding-left: 5%;
  padding-right: 5%;
}

.header-body-home {
  display: grid;
  grid-template-columns: 18% 18% 66%;
  height: 8%;
}

.body-body-home {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  padding: 0;
}

.title-structure{
  display: flex;
  width: 95%;
  margin-top: 5%;
}

.title-structure p{
  font-size: 1.2rem;
  font-weight: 400;
  text-align: start;
}

.content-body-home {
  width: 100%;
  height: 40%;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  color: #000000;
  border-radius: 5px;
  box-shadow: 0px 5px 2px 0px #0000054a;
  padding-top: 25px;
  min-height: 250px;
}

.button-estructure{
  margin-bottom: 25px;
  min-height: 60px;
  height: 8vh;
  background-color: #623AD4;
  width: 90%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif0;
  font-weight: 400;
  font-size: 2rem;
  border-radius: 5px;
  cursor: pointer;

}