.body-home {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 90vh;
  background-color: rgba(255, 255, 255, 0);
  justify-content: center;
  padding-left: 5%;
  padding-right: 5%;
  
  overflow-y: scroll !important;
}

.header-body-home {
  display: grid;
  grid-template-columns: 18% 18% 66%;
  height: 8%;
  height: auto;
}

.body-body-home {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  align-items: self-start;
  padding: 0;
  margin-top: 10vh;
}


.body-body-home p{
  font-size: 1.2rem;
  font-weight: 400;
  text-align: start;
}

.content-body-home {
  width: 100%;
  height: 100vh;
  padding: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  color: #000000;
  border-radius: 5px;
  box-shadow: 0px 5px 2px 0px #0000054a;
}

.title-nivel{
  height: 10%;
  width: 95%;
  display: flex;
  align-items: center;
  margin-bottom: -5%;
  font-size: 0.9rem;
}

.button-estructure-nivel1,
.button-estructure-nivel2,
.button-estructure-nivel3,
.button-estructure-simulado,
.button-estructure-efni{
  margin-bottom: 25px;
  height: 12vh;
  width: 90%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  font-weight: 600;
  font-size: 2rem;
  border-radius: 5px;
  cursor: pointer;
}

.button-estructure-nivel1{
  margin-top: 15px;
  background-color: #FEC601;
}

.button-estructure-nivel2{
  background-color: #fd8f00;
  margin-top: 3%;
}

.button-estructure-nivel3{
  background-color: #D22600;
  margin-top: 3%;
}

.button-estructure-simulado{
  background-color: #8e8e8e;
  margin-top: 3%;
}

.button-estructure-efni{
  background-color: #112353;
  margin-bottom: 5vh;
  margin-top: 3%;
}