@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

html,
body {
  width: 100%;
  margin: 0;
  padding: 0;
}

.body{
  display: flex;
}

.navbar-open{
  width: 100%;
  height: 100%;
  z-index: 1;
}

.navbar-close{
  width: 100%;
  height: 100%;
  z-index: 1;
  overflow: hidden; 
  animation: slideOut 0.5s ease forwards;
}

.body-body{
  margin-top: 5vh;
  position: fixed;
  width: 100%;
  height: 150vh;
  background-color: #ececec;
}

@keyframes slideOut {
  0% {
    width: 30%; /* Começa com a largura especificada */
  }
  100% {
    width: 0; /* Termina com largura 0 */
    display: none;
  }
}