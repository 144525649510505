.name {
  display: flex;
  justify-content: start;
  align-items: center;
  text-align: start;
  min-width: 100px;
  width: auto;
  height: 100%;
  margin-left: 20%;
}

.options {
  display: flex;
  justify-content: end;
  align-items: center;
  width: 90%;
}

.menu{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 90%;
  padding-left: 5%;
}

.background-body {
  width: 100vw;
  height: 5vh;
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: fixed;
  top: 0;
  background-color: #ececec;
  color: black;
  justify-content: center;
  align-items: center;
}

