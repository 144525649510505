.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.Button {
  border: 2px solid #281775 !important;
  color: #281775 !important;
  border-radius: 10px !important;
  display: flex;
  align-items: center;
  justify-content: space-around !important;
  padding: 0 16px;
  margin-top: 15px !important;
  width: 100% !important;
  min-height: 7vh;
}

.Button:hover {
  border: 2px solid #281775 !important;
  color: white !important;
  background-color: #281775 !important;
}

.buttonFilter {
  background-color: #1a1a1a !important;
  width: 80%;
  min-height: 7vh;
  border-radius: 10px !important;
  margin-top: 1rem !important;
}

.input {
  width: 80%;
  border-radius: 10px !important;
  color: #281775 !important;
}

.input:hover {
  color: #281775 !important;
  border-bottom: #281775 !important;
}

.nameButton {
  width: 70%;
  text-align: start;
}

.Box {
  width: 80%;
  height: 60vh;
  overflow-y: scroll;
  scrollbar-width: none;
}

.Box::-webkit-scrollbar {
  display: none; /* Oculta a barra de rolagem */
}

.titlePage {
  width: 90%;
  color: #281775;
  font-weight: 500;
  font-size: 18pt;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.Loading{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25vh;
}