.body-home {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0);
  justify-content: center;
  padding-left: 5%;
  padding-right: 5%;
}

.header-body-home {
  display: grid;
  grid-template-columns: 18% 18% 66%;
  height: 8%;
}

.body-body-home {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  padding: 0;
}

.title-structure {
  display: flex;
  width: 95%;
  margin-top: 5%;
}

.title-structure p {
  font-size: 1.2rem;
  font-weight: 400;
  text-align: start;
}

.content-body-home {
  width: 100%;
  height: auto;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  color: #000000;
  border-radius: 5px;
  box-shadow: 0px 5px 2px 0px #0000054a;
  padding-top: 25px;
  min-height: 250px;
  margin-top: 5%;
}

.title-body-home {
  width: 100%;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.message-home {
  display: flex;
  width: 100%;
  padding: 5%;
  align-items: center;
  justify-content: center;
  height: 90%;
  flex-direction: column;
}

.button {
  width: 90%;
  border: none;
  background: rgb(106, 84, 233);
  color: white;
  height: 6vh;
  border-radius: 2vw;
  letter-spacing: 1px;
  margin-top: 5vh;
  box-shadow: 2px 5px 2px 0px #0000054a;
}
