.header{
  display: grid;
  grid-template-columns: 1fr ;
  height: 400px;
}

.image{
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.image img{
  width: 350px;

}

.logo{
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}

.logo img{
  width: 20%;
  margin-top: 5%;
  margin-right: 5%;
}

.body{
  display: flex;
  justify-content: center;
  align-items: center;
}

table{
  font-size: 1.3rem;
}