@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.main-container {
  background-color: #ececec;

  background-repeat: no-repeat;
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

main.container {
  background: white;
  width: 80%;
  height: 60%;
  min-height: 200px;
  min-width: 250px;
  max-width: 500px;
  padding: 2rem;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

main h2 {
  font-weight: 600;
  margin-bottom: 2rem;
  position: relative;
}

main h2::before {
  content: "";
  position: absolute;
  height: 4px;
  width: 25px;
  bottom: 3px;
  left: 0;
  border-radius: 8px;
  background-color: linear-gradient(
      217deg,
      rgba(209, 0, 216, 0.7),
      rgba(255, 0, 0, 0) 70%
    ),
    linear-gradient(127deg, rgba(86, 0, 184, 0.8), rgba(55, 200, 200, 0) 70%),
    linear-gradient(336deg, rgb(0, 0, 255), rgba(0, 0, 255, 0) 70%);
}

form {
  display: flex;
  flex-direction: column;
}

.required {
  color: red;
}

.image{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-image{
  width: 80%;
  max-width: 200px;
}

.input-field {
  position: relative;
  margin-top: 1rem;
}

form .input-field:first-child {
  margin-bottom: 1.5rem;
}

.input-field .underline::before {
  content: "";
  position: absolute;
  height: 1px;
  width: 100%;
  bottom: -5px;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
}

.input-field .underline::after {
  content: "";
  position: absolute;
  height: 1px;
  width: 100%;
  bottom: -5px;
  left: 0;
  background: linear-gradient(200deg, #4776e6, #8e2de2);
  transform: scaleX(0);
  transition: all 0.3s ease-in-out;
  transform-origin: left;
}

.input-field input:focus ~ .underline::after {
  transform: scaleX(1);
}

.input-field input {
  border: 0;
  outline: none;
  font-size: 0.9rem;
  color: rgba(0, 0, 0, 0.7);
  width: 100%;
}

.input-field input::before {
  color: rgba(0, 0, 0, 0.5);
}

form input[type="submit"] {
  margin-top: 3rem;
  padding: 0.4rem;
  background: #4776e6;
  cursor: pointer;
  color: white;
  font-size: 0.9rem;
  font-weight: 300;
  border-radius: 4px;
  transition: all 0.3s ease;
  border: 0;
}

form input[type="submit"]:hover {
  letter-spacing: 0.5px;
  background: rgb(106, 84, 233);
}

.button {
  margin-top: 3rem;
  padding: 0.4rem;
  background: #4776e6;
  cursor: pointer;
  color: white;
  font-size: 0.9rem;
  font-weight: 300;
  border-radius: 4px;
  transition: all 0.3s ease;
  border: 0;
  text-align: center;
}

.footer {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}

.footer span {
  color: rgba(0, 0, 0, 0.7);
  font-size: 0.8rem;
  text-align: center;
}
