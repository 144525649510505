.navbar-body-open {
  background-color: white;
  width: 30%; 
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 0px 15px #0002;
  background-color: #fff;
  color: black;
  padding-top: 2vh;
  z-index: 1;
  overflow: hidden; 
  animation: slideIn 0.5s ease forwards;
}

.navbar-body-close {
  background-color: white;
  width: 30%; 
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 0px 15px #0002;
  background-color: #fff;
  color: black;
  padding-top: 2vh;
  z-index: 1;
  overflow: hidden; 
  animation: slideOut 0.5s ease forwards;
}

.project-body {
  width: 100%;
  height: 50px;
  display: grid;
  grid-template-columns: 25% 70%;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  background-color: #0000;
  padding-left: 10%;
}

.close-button{
  width: 70%;
  height: 25px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.ClearIcon{
  color: rgba(35, 35, 35, 0.634);
}


hr{
  width: 80%;
}
@keyframes slideIn {
  0% {
    width: 0; /* Começa com largura 0 */
  }
  100% {
    width: 30%; /* Termina com a largura especificada */
  }
}

@keyframes slideOut {
  0% {
    width: 30%; /* Começa com a largura especificada */
  }
  100% {
    width: 0; /* Termina com largura 0 */
  }
}
