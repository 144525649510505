.body-home {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 90vh;
  background-color: rgba(255, 255, 255, 0);
  justify-content: center;
  padding-left: 5%;
  padding-right: 5%;
  overflow-y: scroll !important;
}

.header-body-home {
  display: grid;
  grid-template-columns: 18% 18% 66%;
  height: 8%;
  height: auto;
}

.body-body-home {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  align-items: center;
  padding: 0;
  margin-top: 10vh;
}


.body-body-home p{
  font-size: 8vw;
  font-weight: 400;
  text-align: start;
}

.content-body-home {
  margin-top: 5vh;
  width: 100%;
  height: 1000vh;
  padding: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  color: #000000;
  border-radius: 5px;
  box-shadow: 0px 5px 2px 0px #0000054a;
}

.content-body-home p {
  font-size: 1.2rem;
}

.image{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-image{
  width: 80%;
  max-width: 200px;
}

.select-template{
  width: 90%;
  border-radius: 5px ;
}

.label-template{
  width: 90%;
  min-height: 50vh;
  border: 1px solid #0005;
  overflow-y: scroll;
  margin-top: 15px;
  border-radius: 5px ;
  padding: 2%;
}

.button-estructure-nivel1,
.button-estructure-nivel2,
.button-estructure-nivel3,
.button-estructure-simulado,
.button-estructure-efni{
  margin-bottom: 25px;
  height: 12vh;
  width: 90%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  font-weight: 600;
  font-size: 3.7rem;
  border-radius: 5px;
  cursor: pointer;
}

.button-estructure-nivel1{
  margin-top: 25px;
  background-color: #FEC601;
}

.button-estructure-nivel2{
  margin-top: 25px;
  background-color: #fd8f00;
}

.button-estructure-nivel3{
  margin-top: 25px;
  background-color: #D22600;
}

.button-estructure-simulado{
  margin-top: 25px;
  background-color: #8e8e8e;
}

.button-estructure-efni{
  margin-top: 25px;
  background-color: #112353;
  margin-bottom: 5vh;
}

.paragraph-observation{
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.paragraph-observation p{
  font-size: 1rem;
}

.option-radio{
  display: flex;
  width: 80%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 15px;
}

.option-radio div{
  margin-left: 2%;
  font-size: 1rem;
}