
.body-home {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0);
    align-items: center;
  }
  
.camouflage {
    position: relative; 
    width: 90%;
    min-height: 15vh;
    background-color: rgb(224, 224, 224);
    margin-top: 5vh;
    border-radius: 1vw;
    padding: 2%;
  }
  
  .reflection {
    position: absolute;
    top: 0;
    width: 25px !important;
    left: -100%; 
    height: 100%;
    background-color: rgb(255, 255, 255);
    animation: loading 2s linear infinite;
    filter: blur(10px);
    transform: rotate(20deg);
  }
  
  @keyframes loading {
    0% {
      opacity: 0;
      width: 0%;
      left: 0;
    }
    50% {
      opacity: 0.5;
      width: 100%;
      left: 0;
    }
    100% {
      opacity: 0;
      width: 0%;
      left: 100%;
    }
  }